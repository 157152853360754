import Image from 'next/image';
import Link from 'next/link';
import { Col, Container, Row } from 'react-bootstrap';

const Error = () => {
  return (
    <div className='error_404'>
      <Container>
        <Row className='justify-content-center'>
          <Col lg={7}>
            <Image
              src='/assets/images/404.svg'
              width={398}
              height={247}
              priority={true}
              alt='404'
              style={{ objectFit: 'contain' }}
              className='img-fluid'
            />
            <h1>Page not found</h1>
            <p>
              {`We're very sorry for the inconvenience the page you're trying to
          access was deleted or never existed`}
            </p>
            <Link prefetch={false} href='/'>
              Go to Home{' '}
              <Image
                src='/assets/icons/arrow_right_new.png'
                width={16}
                height={16}
                priority={true}
                alt='404'
                style={{ objectFit: 'contain' }}
                className='img-fluid'
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Error;
